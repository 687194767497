.container {
  padding-top: 5%;
  padding-bottom: 3%;
}
.buttons {
  padding-top: 2%;
}

.pagination {
  align-content: center;
}

.buttons.primary-button {
  padding-left: 2%;
}
.btn-text-right {
  text-align: right;
}
.accordion-button:hover {
  cursor: auto !important;
}
.file-required {
  color: red;
}

.files-inputs {
  letter-spacing: 1px;
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
  color: #424242;
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
  box-sizing: border-box;
  border: 0;
  vertical-align: baseline;
}
