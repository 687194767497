.center {
  text-align: center;
  margin-top: 10%;
}
.image {
  width: 150px;
  height: 150px;
  margin-bottom: 50px;
}
.text {
  color: #001d91;
  font-weight: bold;
  font-size: x-large;
}
