.loading-background {
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  background-color: #00164e5e !important;

  & > * {
    position: relative !important;
    left: 46% !important;
    margin-left: -50px !important;
    top: 50% !important;
    margin-top: -50px !important;
  }
}

.loading-without-background {
  z-index: 5555;
  position: fixed;
  width: 100% !important;
  height: 100% !important;
}

.spinner {
  left: 47%;
  top: 47%;
  position: absolute;
  z-index: 9999;
}
