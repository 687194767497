$dark-blue: #00154e;
$blue: #002c81;
$light-blue: #19a8f3;
$light-blue-max: #e2eef3;
$light-gray-blue: #86b2db;
$dark-gray: #1f1f1f;
$medium-dark-gray: #424242;
$gray: #4d4d4d;
$light-gray: #dddddd;
$red: #ee0102;
$active: #44d371;
$active-hover: #2cb959;
