.instructions {
  padding-bottom: 2% !important;
  padding-top: 0% !important;
}
.instructions .row {
  margin-top: 1%;
}
.contract-iframe {
  position: relative;
  width: 100%;
  height: 750px;
  margin: 0;
  border: none;
  overflow: hidden;
}
/* CSS pantallas de 320px o superior */
@media (min-width: 280px) {
  .instructions-button-actions button {
    margin-top: 5% !important;
  }

  .badge {
    white-space: normal;
    text-align: left;
  }
}

@media (min-width: 320px) {
  .contract-iframe {
    height: 635px;
  }

  .instructions-button-actions button {
    margin-top: 5% !important;
  }

  .badge {
    white-space: normal;
    text-align: left;
  }
}

/* CSS pantalla 768px o superior */
@media (min-width: 768px) {
  .contract-iframe {
    height: 750px;
  }
}
.contract {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 5%;
  padding-right: 5%;
}
.contract-header {
  display: flex;
  justify-content: center;
  background-color: #282c34;
}
.logo {
  position: absolute;
  top: 25px;
  left: 4%;
  z-index: 2;
  max-width: 15%;
}
.title-above {
  position: absolute;
  top: 17vw;
  left: 4%;
}
.subtitle-above {
  position: absolute;
  top: 23vw;
  left: 4%;
  font-size: 1.8vw;
  color: black;
  font-weight: bold;
}
.div-icons {
  padding-top: 5%;
  padding-bottom: 2%;
  margin-left: 10%;
  margin-right: 10%;
}
.icons {
  height: 8vw;
}
.div-text {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
}
.button {
  background-color: #002c81;
}

.instructions-button-actions {
  margin-top: 5% !important;
}

#boton-inicio-contrtato {
  margin-bottom: 2%;
}

.blurred-container {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

span {
  font-weight: bold;
}

.fadeOut {
  -webkit-animation: fadeout 0.3s linear forwards;
  animation: fadeout 0.3s linear forwards;
}
.fadeIn {
  opacity: 1;
  width: 100px;
  height: 100px;
  transition: width 0.3s, height 0.5s, opacity 0.5s 0.5s;
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 0;
    height: 50vh;
  }
  80% {
    opacity: 0;
    height: 1vh;
  }
  100% {
    opacity: 0;
    height: 1vh;
    width: 1vh;
    overflow: hidden;
    display: none;
  }
}

@keyframes fadeout {
  0% {
    opacity: 0;
    height: 50vh;
  }
  80% {
    opacity: 0;
    height: 1vh;
  }
  100% {
    opacity: 0;
    height: 1vh;
    width: 1vh;
    overflow: hidden;
    display: none;
  }
}
