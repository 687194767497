.contract-header {
  display: flex;
  justify-content: center;
  background-color: #282c34;
}
.logo {
  position: absolute;
  top: 25px;
  left: 4%;
  z-index: 2;
  max-width: 15%;
}
.title-above {
  position: absolute;
  top: 17vw;
  left: 4%;
}
.subtitle-above {
  position: absolute;
  top: 23vw;
  left: 4%;
  font-size: 1.8vw;
  color: black;
  font-weight: bold;
}
