@import '../../../styles/colors';

footer {
  background: $blue;
  color: #ffffff;
  font-size: 0.88rem;
  font-weight: 600;
  min-height: 170px;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
  a {
    color: #ffffff !important;
  }
  a:hover {
    color: #44d371 !important;
  }
  .box_logo {
    text-align: left;
    img {
      width: 125px;
    }
  }
  .box_socials {
    .one {
      .box_inp_group {
        display: flex;
        flex-wrap: nowrap;
        .form-control {
          background: #002c81;
          border: 2px solid #fff;
          min-height: 48px;
          color: #ffffff;
          &:focus {
            outline: none;
          }
          &::placeholder {
            color: #ffffff;
          }
        }
        .btn {
          background: #ffffff;
          border-radius: 0 0.3rem 0.3rem 0 !important;
          min-width: 50px !important;
          min-height: 25px;
          margin: 0;
          padding: 0;
          &:hover {
            img {
              opacity: 0.5;
            }
          }
        }
      }
    }
    .two {
      text-align: left;
      margin-top: 10px;
      padding-left: 0px;
      a {
        margin: 0 0.5rem;
      }
    }
  }
  .box_logo {
    text-align: left;
    .list {
      background: none !important;
      border: 0px !important;
    }
  }
  .line {
    border: 2px solid white;
    min-width: 90%;
  }
  .title {
    font-size: large;
  }
  .dr {
    padding-top: 5%;
    padding-bottom: 2%;
  }
}
