.custom-file {
  margin-bottom: 12px !important;

  .custom-file-label {
    background: #fff;
    height: calc(2em + 0.75rem + 6px);
    border-color: #666;
    border-radius: 20px;
    padding-top: 0.7rem;
    cursor: pointer;

    &::after {
      display: none;
    }
  }
}

.wrapper_form_padd {
  .row_form_padd {
    .col_i {
      padding-right: 3.5rem;
    }

    .col_ii {
      padding-left: 3.5rem;
    }
  }
}

.m2-form-group {
  margin-bottom: 23px !important;

  .form-label {
    letter-spacing: 1px;
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
    color: $medium-dark-gray;
    display: inline-block;
    max-width: 100%;
    font-weight: 700;
    box-sizing: border-box;
    border: 0;
    vertical-align: baseline;
  }

  .form-control {
    background: #f0f2f7;
    height: calc(2em + 0.75rem + 6px);
    border-color: transparent;
    border-radius: 20px;
    padding: 0.375rem 0.75rem 0.4rem;
  }

  .m2crowd-input-label-required {
    color: red;
  }
}

.input-group-prepend {
  .input-group-text {
    background-color: #f0f2f7;
    border: 0;
    border-radius: 20px 0 0 20px !important;
  }
}

.input-group-append {
  background: transparent !important;
  border-radius: 20px;

  .input-group-text {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.375rem 1rem;
    margin-bottom: 0;
    background-color: #f0f2f7;
    border: 0;
    border-radius: 0 20px 20px 0 !important;
  }
}

.disabled-style {
  opacity: 0.65 !important;
}

.full-border-radius {
  border-radius: 20px !important;
}
