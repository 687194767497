@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
@import './colors';
@import './text';
@import './form';

// ** STYLES GENERAL **
body {
  font-family: 'Mulish', sans-serif;
  font-size: 18px;
  color: $dark-gray;
}

img {
  max-width: 100%;
}

.m_top {
  margin-top: 75px;
}

.m_botton {
  margin-bottom: 4rem;
}

.ptop {
  padding-top: 75px;
}

.trans {
  -webkit-transition: 0.4s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  transition: 0.4s cubic-bezier(0.2, 0.61, 0.41, 0.95);
}

.show > .btn-primary.dropdown-toggle {
  background: $light-blue-max !important;
}

.btn_to_back {
  position: absolute;
  top: 50%;
  left: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.65rem;
  border-radius: 45px;

  &:hover {
    text-decoration: none;
    background: $light-blue-max;
  }

  .icon {
    font-size: 2.75rem;
    color: $light-blue;
    margin-right: 0.5rem;
    line-height: 1.25;
    padding-bottom: 3px;
  }

  .label {
    font-size: 0.8rem;
    color: $dark-blue;
  }
}

.go_back_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 30px 10px 30px;
  border-radius: 45px;
  letter-spacing: 1px;
  font-size: 0.8rem;
  color: #424242;
  font-weight: 700;
  margin-bottom: 20px;

  .icon {
    font-size: 1.8rem;
    color: $light-blue;
    margin-right: 0.5rem;
    line-height: 1.25;
  }

  .label {
    font-size: 0.8rem;
    color: $dark-blue;
  }

  &:hover {
    background: $light-blue-max;
  }
}

.link_dropdown {
  padding: 0.25rem;
  padding-top: 0.1rem;

  .dropdown-toggle {
    background: transparent;
    border: 0;
    margin: 0;
    text-transform: uppercase;
    color: dimgray !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600 !important;
    font-size: 14px !important;
    min-width: auto !important;
    min-height: 40px;
    cursor: pointer;
    transition: 300ms;
    color: $light-blue !important;
    outline: 0;

    &:hover,
    &:active,
    &:focus {
      background: $light-blue-max !important;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      text-transform: uppercase;
      color: dimgray !important;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600 !important;
      font-size: 14px !important;
    }
  }
}

.d_flex {
  display: flex;

  &.j_content_center {
    justify-content: center;
  }

  &.j_content_start {
    justify-content: flex-start;
  }

  &.j_content_between {
    justify-content: space-between;
  }

  &.a_items_center {
    align-items: center;
  }
}

.container-fluid {
  max-width: 1320px;
}

.wrapper_content {
  min-height: calc(100vh - 250px);
}

.hg_content {
  min-height: calc(100vh - 250px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.c_green {
  color: $active;
}

.c_blue {
  color: $light-blue;
}

.c_blue_marin {
  color: $blue;
}

.fw_700 {
  font-weight: 700 !important;
  font-family: 'Montserrat', sans-serif;
}

.btn {
  text-transform: uppercase;
  font-size: 16px !important;
  font-weight: 700 !important;
  border-radius: 30px !important;
  min-width: 250px;
  margin: 0 0.25rem;
  min-height: 50px;
  padding: 0.75rem 1rem !important;
  border: 0 !important;

  &.btn-success {
    background: $active;

    &:hover {
      background: $active-hover;
    }
  }

  &.btn-light {
    color: #212529;
    background-color: #f0f2f7;
    border-color: #f0f2f7;

    &:hover {
      background: #e1e3e9;
    }
  }
}

.sweet-alert {
  .btn {
    margin-bottom: 0.75rem;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $light-blue;
  color: #ffffff;
}

.bg_m2crowd {
  background-image: url('https://web.m2crowd.com/merchant-commission-contracts/assets/bgm2crowd.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 195px;
  opacity: 0.45;
  margin-top: 4rem;
}

.wrapper_pleca {
  background: $blue;
  padding: 4rem 1rem 3rem;
  margin: 3rem 0;
}

.wrapper_wd {
  text-align: center;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 975px;
}

@media screen and (max-width: 1024px) {
  .btn_to_back {
    top: 100px;
    padding: 0.25rem 0.65rem;

    .icon {
      font-size: 1.7rem;
    }

    .label {
      font-size: 0.7rem;
    }
  }

  .wrapper_detailProj .m2_title {
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 990px) {
  .wrapper_form_padd .row_form_padd {
    .col_i {
      padding-right: 1.25rem;
    }

    .col_ii {
      padding-left: 1.25rem;
    }
  }
}

@media screen and (max-width: 900px) {
  .wrapper_up_new_project {
    padding: 4rem 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .bg_m2crowd {
    height: 100px;
    opacity: 0.25;
  }

  footer {
    padding-bottom: 1.75rem;

    .box_logo {
      margin-bottom: 1rem;
    }

    .box_socials {
      .one,
      .two {
        text-align: left;
        margin-top: 1.25rem;
      }
    }
  }

  .wrapper_content,
  .hg_content {
    min-height: calc(100vh - 460px);
  }

  .drawer_count {
    background: #ffffff !important;
  }
}

@media screen and (max-width: 640px) {
  .ptop {
    padding-top: 60px;
  }
}

@media screen and (max-width: 575px) {
  footer.mainfooter .box_socials .two {
    text-align: left;
  }

  .m2_title {
    &.t_small {
      font-size: 30px !important;
      line-height: 1.15 !important;
    }
  }

  .wrapper_steps_proj {
    margin: 3rem 0 !important;
  }

  .wrapper_detailProj {
    .picture_project_detail {
      margin: 2rem 0 3rem !important;
    }
  }

  .wrapper_items_project {
    .box_info_project {
      bottom: 0 !important;

      .info_project {
        opacity: 1 !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .ptop {
    padding-top: 50px;
  }

  .wrapper_up_new_project {
    padding: 3rem 0 !important;

    .m2_title {
      font-size: 1.75rem !important;
      line-height: 1.25;
    }
  }

  .m2_title {
    &.t_small {
      font-size: 27px !important;
      line-height: 1.1 !important;
    }
  }

  .wrapper_items_project {
    .item_project {
      .box_info_project {
        .info_project {
          width: 90% !important;
          bottom: 25px !important;
          font-size: 1rem !important;
        }
      }
    }
  }

  .bg_m2crowd {
    display: none;
  }

  .m2_paragraph {
    font-size: 16px !important;
    line-height: 1.3;
  }

  .wrapper-account {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 360px) {
  .wrapper_up_new_project {
    .m2_title {
      font-size: 1.5rem !important;
      line-height: 1.25;
    }
  }

  .wrapper_proj_catalg {
    .box_navs_tabs {
      .nav-link {
        padding: 0.5rem;
      }
    }
  }
}
