$blue: #002c81;
$gray: #f0f2f7;
$white: #ffffff;

.container-final {
  color: $blue;
}

.image-final {
  width: 100%;
  margin-bottom: 0;
}

.centered {
  position: absolute;
  top: 40%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.container-text {
  position: relative;
  text-align: left;
  text-align: center;
  margin-bottom: 0;
}

.pill-button-aqui {
  background-color: transparent;
  border: 2px solid $blue;
  color: $blue;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}

.pill-button-finalizar {
  background-color: $blue;
  border: 5px solid $blue;
  color: $white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 3px;
  cursor: pointer;
  border-radius: 22px;
  width: 445px;
  height: 46px;
  font-size: 21px;
}

.pill-button-finalizar img {
  margin-left: 5px;
  margin-bottom: 5px;
}

.background-footer {
  background-color: $gray;
  padding-bottom: 30px;
  padding-top: 30px;
}

#tick {
  max-width: 22px;
  max-height: 22px;
}

#top-left-logo {
  position: absolute;
  top: 25px;
  left: 4%;
  text-align: left;
}

#description {
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 0;
  margin-top: 30px;
}

#m2crowd_logo {
  max-width: 30%;
}
